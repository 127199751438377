import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './editform.css';

const Extra = [
  { id: 1, item: 'Rafting', description: "", number_of_guest: "", price: "" },
  { id: 2, item: 'Exotic Food', description: "", number_of_guest: "", price: "" },
  { id: 3, item: 'Pick and Drop', description: "", number_of_guest: "", price: "" },
  { id: 4, item: 'BBQ', description: "", number_of_guest: "", price: "" },
  { id: 5, item: 'Breakfast', description: "", number_of_guest: "", price: "" },
];

const PropertyForm = () => {
  const location = useLocation();
  const propertyData = location.state?.propertyData || {};
  const [selectedExtra, setSelectedExtra] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Pre-fill form with property data if available
    if (propertyData) {
      // Update your form state with propertyData
      console.log('Editing property:', propertyData);
    }
  }, [propertyData]);

  const handleExtraClick = (item) => {
    setSelectedExtra(prevSelected =>
      prevSelected.includes(item)
        ? prevSelected.filter(extra => extra !== item)
        : [...prevSelected, item]
    );
  };

  const toggleModal = () => {
    setShowModal(prevShow => !prevShow);
  };

  return (
    <div className="property-form-container">
      <h1>Property Details</h1>
      <form className="property-form">
        <div className="form-group">
          <label htmlFor="propertyName">Property Name:</label>
          <input
            type="text"
            id="propertyName"
            name="propertyName"
            defaultValue={propertyData?.property_name || ''}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="locationCity">Location (City & Country):</label>
          <div className="location-container">
            <input
              type="text"
              id="locationCity"
              name="locationCity"
              placeholder="City"
              defaultValue={propertyData?.city || ''}
              required
            />
            <input
              type="text"
              id="locationCountry"
              name="locationCountry"
              placeholder="Country"
              defaultValue={propertyData?.country || ''}
              required
            />
          </div>
        </div>

        <div className="form-group single-line">
          <div className="form-field">
            <label htmlFor="peopleCount">People Count:</label>
            <input
              type="number"
              id="peopleCount"
              name="peopleCount"
              min="1"
              defaultValue={propertyData?.guest_count || ''}
              required
            />
          </div>

          <div className="form-field">
            <label htmlFor="bedroomCount">Bedroom Count:</label>
            <input
              type="number"
              id="bedroomCount"
              name="bedroomCount"
              min="1"
              defaultValue={propertyData?.bedroom_count || ''}
              required
            />
          </div>

          <div className="form-field">
            <label htmlFor="bathroomCount">Bathroom Count:</label>
            <input
              type="number"
              id="bathroomCount"
              name="bathroomCount"
              min="1"
              defaultValue={propertyData?.bathroom_count || ''}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="PricePerNight">Price Per Night:</label>
          <input
            type="text"
            id="PricePerNight"
            name="PricePerNight"
            defaultValue={propertyData?.price_per_night || ''}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="SelectView">Select View:</label>
          <input
            type="text"
            id="SelectView"
            name="SelectView"
            defaultValue={propertyData?.select_view
              || ''}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="PropertyType">Property Type:</label>
          <input
            type="text"
            id="PropertyType"
            name="PropertyType"
            defaultValue={propertyData?.property_type
              || ''}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            name="description"
            rows="4"
            defaultValue={propertyData?.property_description
                || ''}
            required
          ></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="propertyImages">Property Images (up to 3):</label>
          <input type="file" id="propertyImages" name="propertyImages" accept="image/*" multiple />

        </div>

        <div className="form-group">
          <label style={{ fontSize: 20, fontWeight: '550' }}>Extra Service</label>
          <div className="extra-services">
            {Extra.map((item) => (
              <ul className='service-ex' key={item.id}>
                <li
                  style={{
                    background: selectedExtra.includes(item) ? '#F15A29' : '#F3F4F6',
                    width: '80%',
                    cursor: 'pointer',
                    height: 45,
                    borderRadius: 5,
                    textAlign: 'center',
                    color: selectedExtra.includes(item) ? 'white' : '#6B7280',
                    fontSize: 16,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => handleExtraClick(item)}
                >
                  {item.item}
                </li>
              </ul>
            ))}
            <div
              className="add-service-button"
              onClick={toggleModal}
            >
              +
            </div>
          </div>
        </div>

        {showModal && (
          <div className="modal">
            <h2>Add New Service</h2>
            {/* Add form fields for new service */}
            <button onClick={toggleModal}>Close</button>
          </div>
        )}

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default PropertyForm;
