import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faSolidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faRegularStar } from '@fortawesome/free-regular-svg-icons';

const Rating = ({ initialRating = 0, reviewCount = 0 }) => {
  const [rating, setRating] = useState(initialRating);
  const [hoverRating, setHoverRating] = useState(0);

  const handleMouseEnter = (index) => {
    setHoverRating(index);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  const handleClick = (index) => {
    setRating(index);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* Render 5 stars */}
      {[...Array(5)].map((_, index) => {
        const starIndex = index + 1;
        return (
          <FontAwesomeIcon
            key={index}
            icon={
              starIndex <= (hoverRating || rating)
                ? faSolidStar
                : faRegularStar
            }
            style={{
              color: '#FFD700',
              marginRight: '2px',
              cursor: 'pointer'
            }}
            onMouseEnter={() => handleMouseEnter(starIndex)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick(starIndex)}
          />
        );
      })}
      {/* Display the number of reviews and the rating */}
      {reviewCount > 0 && (
        <span style={{ marginLeft: '5px', fontWeight: 'bold' }}>
          {reviewCount} ({rating})
        </span>
      )}
    </div>
  );
};

export default Rating;
