import React, { Component } from 'react';
import StarRating from './StarRating'; // Adjust the import path as necessary
import './ReviewForm.css'

class ReviewForm extends Component {
  state = {
    review: '',
    rating: 0,
    errors: {
      review: '',
      rating: ''
    }
  };

  handleReviewChange = (event) => {
    this.setState({ review: event.target.value });
  };

  handleRatingChange = (rating) => {
    this.setState({ rating });
  };

  validateForm = () => {
    const { review, rating } = this.state;
    let valid = true;
    const errors = {
      review: '',
      rating: ''
    };

    if (!review.trim()) {
      errors.review = 'Review is required.';
      valid = false;
    }

    if (rating <= 0) {
      errors.rating = 'Rating is required.';
      valid = false;
    }

    this.setState({ errors });
    return valid;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      // Handle form submission (e.g., send data to API)
      console.log('Review:', this.state.review);
      console.log('Rating:', this.state.rating);
      // Reset form
      this.setState({ review: '', rating: 0, errors: { review: '', rating: '' } });
    }
  };

  render() {
    const { review, rating, errors } = this.state;

    return (
      <div style={{ width: '100%', margin: '0 auto', padding: '20px',  borderRadius: '8px' }}>
        <h2>Post a Review</h2>
        <form onSubmit={this.handleSubmit}>
          <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', marginBottom: '5px' }}>Rating:</label>
            <StarRating rating={rating} onRatingChange={this.handleRatingChange} />
            {errors.rating && <div style={{ color: 'red', marginTop: '5px' }}>{errors.rating}</div>}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', marginBottom: '5px' }}>Review:</label>
            <textarea 
              value={review}
              onChange={this.handleReviewChange}
              rows="2"
              style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
            {errors.review && <div style={{ color: 'red', marginTop: '5px' }}>{errors.review}</div>}
          </div>
          <button className='review-btn' type="submit">
            Submit Review
          </button>
        </form>
      </div>
    );
  }
}

export default ReviewForm;
