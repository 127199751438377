import React, { useState, useRef } from 'react';
import './profile.css';

function Profile({ imageUrl, name, location, cancellationPolicy, rating, reviews, yearsHosting, school, languages, obsession, onClose }) {
  const [isVisible, setIsVisible] = useState(true);
  const [newImage, setNewImage] = useState(imageUrl);
  const fileInputRef = useRef(null); // Ref for the file input

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger file input click
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="centered-container777">
      <div className="card456">
        <div className="card-header11">
          <div className="image-container">
            <img 
              src={newImage} 
              alt={name} 
              onClick={handleImageClick} 
              className="profile-image"
            />
            {/* Only show the edit icon on mobile */}
            <div className="edit-icon" onClick={handleImageClick}>
              &#9998;
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="file-input"
              ref={fileInputRef} // Attach ref to the file input
              style={{ display: 'none' }} // Hide the file input element
            />
          </div>
          <div className='close' onClick={onClose}>x</div>
          <div className="superhost-badge"  onClick={handleImageClick}>+</div>
        </div>
        <div className="card-body">
          <h2>{name}</h2>
          <div className="info">
            <p><strong>Location:</strong> {location}</p>
            <p><strong>Cancellation Policy:</strong> {cancellationPolicy}</p>
            <p><strong>Speaks:</strong> {languages}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
