import React, { useState, useEffect } from 'react';
import './sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaypal, faCcMastercard } from '@fortawesome/free-brands-svg-icons';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

function Sidebar() {
  const [balance, setBalance] = useState(0);
  const [availableJobs, setAvailableJobs] = useState(0);
  const [earnedThisMonth, setEarnedThisMonth] = useState(0);
  const [expectedEarnings, setExpectedEarnings] = useState(0);
  const [isPaypalPopupVisible, setIsPaypalPopupVisible] = useState(false);
  const [isUserPlusPopupVisible, setIsUserPlusPopupVisible] = useState(false);
  const [paypalId, setPaypalId] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [payoutMethod, setPayoutMethod] = useState('');

  useEffect(() => {
    // Replace with your actual API endpoint
    fetch('https://api.example.com/earnings')
      .then(response => response.json())
      .then(data => {
        setBalance(data.balance);
        setAvailableJobs(data.availableJobs);
        setEarnedThisMonth(data.earnedThisMonth);
        setExpectedEarnings(data.expectedEarnings);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handlePaypalIconClick = () => {
    setIsPaypalPopupVisible(true);
    setIsUserPlusPopupVisible(false); // Hide User Plus popup
  };

  const handleUserPlusIconClick = () => {
    setIsUserPlusPopupVisible(true);
    setIsPaypalPopupVisible(false); // Hide PayPal popup
  };

  const handleClosePaypalPopup = () => {
    setIsPaypalPopupVisible(false);
    setSuccessMessage('');
  };

  const handleCloseUserPlusPopup = () => {
    setIsUserPlusPopupVisible(false);
    setSuccessMessage('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the form submission logic here
    console.log('PayPal ID:', paypalId);
    setSuccessMessage('Your PayPal ID has been submitted successfully!');
    setPaypalId('');

    // Close the popup after showing the success message
    setTimeout(() => {
      setIsPaypalPopupVisible(false);
    }, 3000);
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    // Handle the form submission logic here
    console.log('Form submitted');
    setSuccessMessage('Your details have been submitted successfully!');

    // Close the popup after showing the success message
    setTimeout(() => {
      setIsUserPlusPopupVisible(false);
    }, 3000);
  };

  const handlePayoutMethodChange = (e) => {
    setPayoutMethod(e.target.value);
  };

  return (
    <div className="sidebar">
      <div className='earningcontent'>
        <h2>Earning</h2>
        <div className="balance">
          <p>Personal Balance</p>
          <h1>${balance}</h1>
          <p>Available</p>
          <h4>{availableJobs} Jobs (${availableJobs * 50})</h4>
          <p>Earned this Month</p>
          <h4>${earnedThisMonth}</h4>
          <p>Expected Earning</p>
          <h4>${expectedEarnings}</h4>
        </div>
        <div className="withdraw-method">
          <h2 style={{ whiteSpace: 'nowrap', fontSize: '20px' }}>Withdraw Method</h2>
          <div className="methods">
            <FontAwesomeIcon style={{cursor:'pointer'}}
              icon={faPaypal} 
              size="2x" 
              onClick={handlePaypalIconClick} 
            />
            <FontAwesomeIcon  style={{cursor:'pointer'}}
              icon={faUserPlus} 
              size="2x" 
              onClick={handleUserPlusIconClick}
            />
          </div>
        </div>
      </div>

      {/* PayPal Popup */}
      {isPaypalPopupVisible && (
        <div className="popup-overlay786">
          <div className="popup-content786">
            <h2>Enter Your PayPal ID</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={paypalId}
                onChange={(e) => setPaypalId(e.target.value)}
                placeholder="PayPal ID"
                required
              />
              <div className='ff'>
                <button 
                  style={{ background: 'transparent', color: 'black', border: '1px solid black', fontSize: '12px' }} 
                  type="submit"
                >
                  Submit
                </button>
                <button 
                  style={{ background: 'transparent', color: 'black', border: '1px solid black', fontSize: '12px' }} 
                  type="button" 
                  onClick={handleClosePaypalPopup}
                >
                  Close
                </button>
              </div>
            </form>
            {successMessage && <p className="success-message">{successMessage}</p>}
          </div>
        </div>
      )}

      {/* User Plus Popup */}
      {isUserPlusPopupVisible && (
        <div className="popup-overlay786">
          <div className="popup-content786">
            <h2>Host Banking and Payout Details Form</h2>
            <form onSubmit={handleSubmit1}>
            <label for="fullName">Full Name (as it appears on your bank account):</label>
              <input
                type="text"
                placeholder="Full Name:"
                required
              />
               <label for="email">Email Address:</label>
              <input
                type="email"
                placeholder="Email Address:"
                required
              />
               <label for="phone">Phone Number:</label>
              <input
                type="number"
                placeholder="Phone Number:"
                required
              />
               <label for="payoutMethod">Preferred Payout Method:</label>
              <select id="payoutMethod" name="payoutMethod" value={payoutMethod} onChange={handlePayoutMethodChange} required>
                <option value="">Select...</option>
                <option value="bankTransfer">Bank Transfer</option>
                <option value="westernUnion">Western Union</option>
                {/* <option value="paypal">PayPal</option> */}
              </select>

              {/* Conditional rendering based on payout method */}
              {payoutMethod === 'bankTransfer' && (
                <div id="bankTransferFields">
                  <label htmlFor="bankName">Bank Name:</label>
                  <input type="text" id="bankName" name="bankName" required />

                  <label htmlFor="bankAddress">Bank Address:</label>
                  <input type="text" id="bankAddress" name="bankAddress" required />

                  <label htmlFor="bankCountry">Bank Country:</label>
                  <input type="text" id="bankCountry" name="bankCountry" required />

                  <label htmlFor="accountNumber">Bank Account Number:</label>
                  <input type="text" id="accountNumber" name="accountNumber" required />

                  <label htmlFor="accountType">Account Type:</label>
                  <select id="accountType" name="accountType" required>
                    <option value="checking">Checking</option>
                    <option value="savings">Savings</option>
                  </select>

                  <label htmlFor="swiftCode">SWIFT/BIC Code:</label>
                  <input type="text" id="swiftCode" name="swiftCode" required />

                  <label htmlFor="iban">IBAN (International Bank Account Number):</label>
                  <input type="text" id="iban" name="iban" required />

                  <label htmlFor="routingNumber">Routing Number/Sort Code:</label>
                  <input type="text" id="routingNumber" name="routingNumber" required />
                </div>
              )}
              
              {payoutMethod === 'westernUnion' && (
                <div id="westernUnionFields">
                  <label htmlFor="wuRecipientName">Recipient Name:</label>
                  <input type="text" id="wuRecipientName" name="wuRecipientName" required />

                  <label htmlFor="wuRecipientAddress">Recipient Address:</label>
                  <input type="text" id="wuRecipientAddress" name="wuRecipientAddress" required /><br />

                  <label htmlFor="wuCountry">Country:</label><br />
                  <input type="text" id="wuCountry" name="wuCountry" required />

                  <label htmlFor="wuPhone">Phone Number:</label>
                  <input type="text" id="wuPhone" name="wuPhone" required />
                </div>
              )}
              
              {/* {payoutMethod === 'paypal' && (
                <div id="paypalFields">
                  <label htmlFor="paypalEmail">PayPal Email Address:</label>
                  <input type="email" id="paypalEmail" name="paypalEmail" required />
                </div>
              )} */}

              <select id="payoutOption" name="payoutOption" style={{width:'100%'}} required>
                <option value="oneTime">One-time Payout: 24 hours after guest check-in</option>
                <option value="monthly">Monthly Payout: 24 hours after guest check-in, and every 25 days thereafter if it’s a monthly stay</option>
              </select>

              <input type="checkbox" id="consent" name="consent" required />
              <label htmlFor="consent">I confirm that the information provided is accurate and complete to the best of my knowledge.</label>

              <input type="checkbox" id="agreement" name="agreement" required />
              <label htmlFor="agreement">I agree to the platform's Terms of Service and Privacy Policy.</label>
              
              <div className='ff'>
                <button 
                  style={{ background: 'transparent', color: 'black', border: '1px solid black', fontSize: '12px' }} 
                  type="submit"
                >
                  Submit
                </button>
                <button 
                  style={{ background: 'transparent', color: 'black', border: '1px solid black', fontSize: '12px' }} 
                  type="button" 
                  onClick={handleCloseUserPlusPopup}
                >
                  Close
                </button>
              </div>
            </form>
            {successMessage && <p className="success-message">{successMessage}</p>}
          </div>
        </div>
      )}
    </div>
  );
}

export default Sidebar;
